import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const app = firebase.initializeApp({
	apiKey: "AIzaSyD3Gizs2jVbPoGTA7Uswpu1zG84XRtviF8",
	authDomain: "psyche-inspired-app.firebaseapp.com",
	projectId: "psyche-inspired-app",
	storageBucket: "psyche-inspired-app.appspot.com",
	messagingSenderId: "748636099781",
	appId: "1:748636099781:web:5dd88e430146e216d4ccac",
	measurementId: "G-3LS0WHLEEX"
});

const auth = app.auth();
const db = app.firestore();
const storage = app.storage();
const analytics = firebase.analytics();

export {auth, db, storage, analytics};
export default app;