// React
import React, {useRef, useState} from 'react';

// Image imports
import artwork from "../Images/Psyche_Inspired_Artwork_1024x1024.png"

// Material UI components
import {Button, CircularProgress, Grid, Snackbar, TextField, Typography, withStyles} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import {ThemeProvider} from "@material-ui/core/styles";
import {createTheme} from '@material-ui/core/styles';
import {analytics, auth, db} from "../firebase";
import {useDispatch} from 'react-redux'
import {setUserInfo} from "../Redux/user";

// Custom theme for dark mode login
const theme = createTheme({
	palette: {
		type: "dark",
		primary: {
			main: "#fff",
		},
	},
	typography: {
		fontFamily: ["Rubik", 'sans-serif'].join(','),
		h1: {
			fontWeight: 700,
			fontSize: "xxx-large"
		},
		h2: {
			fontWeight: 700,
			fontSize: "xx-large"
		},
		h3: {
			fontWeight: 700,
			fontSize: "x-large"
		},
	},
});

const FormButton = withStyles(() => ({
	root: {
		backgroundColor: "#f9a000",
		color: "#ffffff",
		'&:hover': {
			backgroundColor: "#e69500",
		},
		fontSize: "larger"
	},
}))(Button);

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AuthenticationForm(props) {
	const [accountExists, setAccountExists] = useState(false);

	return (
		<ThemeProvider theme={theme}>
			<Grid container alignItems={"center"} justifyContent={"center"} spacing={5} style={{height: "100vh"}}>
				<Grid item sm={6}>
					<>
						<Typography variant="h1" style={{color: "#f9a000", marginBottom: 30}}>
							{accountExists ? "Login" : "Sign up"}
						</Typography>
						<Grid container spacing={4} justifyContent={"flex-end"}>
							{
								accountExists ?
									<LoginForm accountType={props.accountType}/> :
									<SignupForm accountType={props.accountType}/>
							}
							<Grid item sm={12}>
								<Typography variant="subtitle1" style={{color: "#e0e0e0"}}>
									{accountExists ? "Not registered yet?" : "Already have an account?"}
									&nbsp;
									<span style={{cursor: "pointer", color: "#ef5966"}}
									      onClick={() => {
										      setAccountExists(!accountExists)
									      }}>
										{accountExists ? "Create an account" : "Log in"}
									</span>
								</Typography>
							</Grid>
						</Grid>
					</>
				</Grid>
				<Grid item sm={6}>
					<img src={artwork} alt="Psyche Inspired Artwork" style={{width: "100%"}}/>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}

function LoginForm(props) {
	// Dispatch for redux
	const dispatch = useDispatch();

	// Form fields references
	const email = useRef();
	const password = useRef();

	// Form fields error status
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);

	// Dynamic snackbar error message
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [alertSeverity, setAlertSeverity] = useState("error");

	const [loadingState, setLoadingState] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackbar(false);
	};

	function loginWithFirebase() {
		// Form validation
		if (emailError || passwordError) {
			//	Show snackbar
			setAlertSeverity("error");
			setSnackbarMessage("Please fill out all the required fields.")
			setOpenSnackbar(true);
			return;
		}

		setLoadingState(true);

		// calls the firebase auth signin method
		auth.signInWithEmailAndPassword(email.current.value, password.current.value)
			.then((userCredential) => {
				// Signed in
				const user = userCredential.user;

				// Get user from Firestore and set the details in redux
				db.collection(props.accountType).doc(user.uid).get()
					.then((doc) => {
						if (doc.exists) {
							//	User exists in db
							const userData = doc.data();

							// Set user info in redux
							const userInfo = {
								"currentUser": JSON.stringify(user),
								"firstName": userData.firstName,
								"lastName": userData.lastName,
								"emailAddress": userData.emailAddress,
								"accountType": userData.accountType,
								"selectedArtists": userData.selectedArtists
							}

							dispatch(setUserInfo(userInfo));

							// set user in localStorage for future sessions
							localStorage.setItem("user", JSON.stringify(user));
						}

						setLoadingState(false);
					})
					.catch((error) => {
						setLoadingState(false);

						const errorCode = error.code;
						const errorMessage = error.message;

						setAlertSeverity("error");
						setOpenSnackbar(true);
						setSnackbarMessage("Sorry, we encountered an error while logging you in. Please try again.");

						analytics.logEvent('error', {
							location: 'sign in getting data',
							errorCode: errorCode,
							errorMessage: errorMessage
						});
					})
			})
			.catch((error) => {
				setLoadingState(false);

				const errorCode = error.code;
				const errorMessage = error.message;

				setAlertSeverity("error");
				setOpenSnackbar(true);
				setSnackbarMessage(errorMessage);

				analytics.logEvent('error', {
					location: 'sign in auth',
					errorCode: errorCode,
					errorMessage: errorMessage
				});
			});
	}

	return (
		<>
			<Grid item sm={12}>
				<TextField
					required
					inputRef={email}
					fullWidth={true}
					label="Email Address"
					type={"email"}
					variant="outlined"
					onBlur={(event) => {
						setEmailError(event.target.value === "") // Sets T/F based on emptiness
					}}/>
			</Grid>
			<Grid item sm={12}>
				<TextField
					required
					inputRef={password}
					fullWidth={true}
					label="Password"
					type={"password"}
					variant="outlined"
					onBlur={(event) => {
						setPasswordError(event.target.value === "") // Sets T/F based on emptiness
					}}/>
			</Grid>
			<Grid item>
				<span style={{cursor: "pointer", color: "#ef5966"}}
					onClick={() => {
						// Form validation
						if (emailError) {
							//	Show snackbar
							setAlertSeverity("error");
							setSnackbarMessage("Please fill out the email.")
							setOpenSnackbar(true);
							return;
						}

						auth.sendPasswordResetEmail(email.current.value)
							.then(() => {
								setAlertSeverity("success");
								setSnackbarMessage("Please check your email for further instructions.");
								setOpenSnackbar(true);
								return;
							})
							.catch(() => {		
								setAlertSeverity("error");
								setOpenSnackbar(true);
								setSnackbarMessage("Sorry, we encountered an error while resetting your password. Please try again.");
							});
				      }}>
					Forget Password?
				</span>
			</Grid>
			<Grid item sm={12}>
				<FormButton
					disableElevation
					variant="contained"
					size={"large"}
					type={"submit"}
					style={{width: "100%"}}
					onClick={loginWithFirebase}>
					Login
					{loadingState && <CircularProgress thickness={5} style={{marginLeft: 20}}/>}
				</FormButton>
			</Grid>
			<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={alertSeverity}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
}

function SignupForm(props) {
	// Dispatch for redux
	const dispatch = useDispatch();

	// Form fields references
	const firstName = useRef();
	const lastName = useRef();
	const email = useRef();
	const password = useRef();
	const passwordCheck = useRef();

	// Form fields error status
	const [firstNameError, setFirstNameError] = useState(false);
	const [lastNameError, setLastNameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [passwordCheckError, setPasswordCheckError] = useState(false);

	const [passwordCheckHelperText, setPasswordCheckHelperText] = useState("");

	// Dynamic snackbar error message
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const [loadingState, setLoadingState] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackbar(false);
	};

	function signUpWithFirebase() {
		// Form validation
		if (firstNameError || lastNameError || emailError || passwordError || passwordCheckError) {
			//	Show snackbar
			setSnackbarMessage("Please fill out all the required fields.")
			setOpenSnackbar(true);
			return;
		}

		setLoadingState(true);

		// calls the firebase auth signup method
		auth.createUserWithEmailAndPassword(email.current.value, password.current.value)
			.then((userCredential) => {
				// Signed in
				const user = userCredential.user;

				// Create user in Firestore and set the details in redux
				db.collection(props.accountType).doc(user.uid).set({
					firstName: firstName.current.value,
					lastName: lastName.current.value,
					emailAddress: email.current.value,
					accountType: props.accountType,
					selectedArtists: []
				})
					.then(() => {
						//	User has been added to db

						// Set user info in redux
						const userInfo = {
							"currentUser": JSON.stringify(user),
							"firstName": firstName.current.value,
							"lastName": lastName.current.value,
							"emailAddress": email.current.value,
							"accountType": props.accountType,
							"selectedArtists": []
						}
						dispatch(setUserInfo(userInfo));

						// set user in localStorage for future sessions
						localStorage.setItem("user", JSON.stringify(userInfo));

						setLoadingState(false);
					})
					.catch((error) => {
						setLoadingState(false);

						const errorCode = error.code;
						const errorMessage = error.message;

						analytics.logEvent('error', {
							location: 'sign up setting data',
							errorCode: errorCode,
							errorMessage: errorMessage
						});

						//	Delete authenticated user
						user.delete().then(() => {
							// User deleted.
							//	Show snackbar
							setOpenSnackbar(true);
							setSnackbarMessage("Sorry, we encountered an error while creating your account. Please try again.");
						}).catch((error) => {
							// Refresh page?? Contact us??
						});
					})
			})
			.catch((error) => {
				setLoadingState(false);

				const errorCode = error.code;
				const errorMessage = error.message;

				// Show auth error
				setOpenSnackbar(true);
				setSnackbarMessage(errorMessage);

				analytics.logEvent('error', {
					location: 'sign up auth',
					errorCode: errorCode,
					errorMessage: errorMessage
				});
			});
	}

	return (
		<>
			<Grid container item spacing={3}>
				<Grid item sm={6}>
					<TextField
						required
						inputRef={firstName}
						fullWidth={true}
						label="First Name"
						variant="outlined"
						error={firstNameError}
						helperText={firstNameError ? "Please fill out this field" : ""}
						onBlur={(event) => {
							setFirstNameError(event.target.value === "") // Sets T/F based on emptiness
						}}
					/>
				</Grid>
				<Grid item sm={6}>
					<TextField
						required
						inputRef={lastName}
						fullWidth={true}
						label="Last Name"
						variant="outlined"
						error={lastNameError}
						helperText={lastNameError ? "Please fill out this field" : ""}
						onBlur={(event) => {
							setLastNameError(event.target.value === "") // Sets T/F based on emptiness
						}}/>
				</Grid>
			</Grid>
			<Grid item sm={12}>
				<TextField
					required
					inputRef={email}
					fullWidth={true}
					label="Email Address"
					type={"email"}
					variant="outlined"
					error={emailError}
					helperText={emailError ? "Please fill out this field" : ""}
					onBlur={(event) => {
						setEmailError(event.target.value === "") // Sets T/F based on emptiness
					}}/>
			</Grid>
			<Grid container item spacing={3}>
				<Grid item sm={6}>
					<TextField
						required
						inputRef={password}
						fullWidth={true}
						label="Password"
						type={"password"}
						variant="outlined"
						error={passwordError}
						helperText={passwordError ? "Please fill out this field" : ""}
						onBlur={(event) => {
							setPasswordError(event.target.value === "") // Sets T/F based on emptiness
						}}/>
				</Grid>
				<Grid item sm={6}>
					<TextField
						required
						inputRef={passwordCheck}
						fullWidth={true}
						label="Confirm Password"
						type={"password"}
						variant="outlined"
						error={passwordCheckError}
						helperText={passwordCheckHelperText}
						onBlur={(event) => {
							if (event.target.value === "") {
								setPasswordCheckError(true)
								setPasswordCheckHelperText("Please enter the password again")
							} else if (event.target.value !== password.current.value) {
								setPasswordCheckError(true)
								setPasswordCheckHelperText("Passwords do not match")
							} else {
								setPasswordCheckError(false)
								setPasswordCheckHelperText("")
							}
						}}/>
				</Grid>
			</Grid>
			<Grid item sm={12}>
				<FormButton
					disableElevation
					variant="contained"
					size={"large"}
					type={"submit"}
					style={{width: "100%"}}
					onClick={signUpWithFirebase}>
					Create Account
					{loadingState && <CircularProgress thickness={5} style={{marginLeft: 20}}/>}
				</FormButton>
			</Grid>
			<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error">
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
}

export default AuthenticationForm;