import React from 'react';
import {AppBar, Box, Button, Dialog, Grid, makeStyles, Slide, Toolbar, Typography} from "@material-ui/core";
import {AspectRatio, Close} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {setDialogStatus} from "../Redux/dialog";
import dummyData from "../dataObjectStructure.json";
import { storage } from '../firebase';

import pdfIcon from "../Images/pdf-icon.jpg"
import playIcon from "../Images/play-icon.jpg"
import webIcon from "../Images/web-icon.jpg"

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
		backgroundColor: "#302144"
	}
}));

// This function animates the fullscreen dialog reveal
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function ReviewDialog(props) {
	const dispatch = useDispatch();

	const open = useSelector((state) => state.dialog.dialogOpenStatus);
	const displayData = useSelector((state) => state.dialog.displayData);

	const classes = useStyles();

	const handleClose = () => {
		dispatch(setDialogStatus({
			dialogOpenStatus: false,
			displayData: dummyData
		}))
	};

	function getResourceFull(sampleWork) {
		if (sampleWork.url.includes(".pdf")) {
			return <img width={"100%"} src={pdfIcon} alt={sampleWork.description} onClick={() => { window.open(sampleWork.url, "_blank") }} onMouseOver={() => { document.body.style.cursor = "pointer" }} onMouseOut={() => { document.body.style.cursor = "auto" }} />
		} else if (sampleWork.url.includes("youtu") || sampleWork.url.includes("vimeo")) {
			return <img width={"100%"} src={playIcon} alt={sampleWork.description} onClick={() => { window.open(sampleWork.url, "_blank") }} onMouseOver={() => { document.body.style.cursor = "pointer" }} onMouseOut={() => { document.body.style.cursor = "auto" }} />
		} else if (sampleWork.url.includes("http")) {
			return <img width={"100%"} src={webIcon} alt={sampleWork.description} onClick={() => { window.open(sampleWork.url, "_blank") }} onMouseOver={() => { document.body.style.cursor = "pointer" }} onMouseOut={() => { document.body.style.cursor = "auto" }} />
		}
		else {
			return <img width={"100%"} src={sampleWork.url} alt={sampleWork.description} />
		}
	}

	function showOriginalImage(fileName) {
		const storageRef = storage.ref();

		fileName = fileName.replace('static/Art2024/', '');

		storageRef.child('/Art2024/' + fileName).getDownloadURL()
		.then((url) => {
			window.open(url, "_blank");
		})
		.catch((error) => {
			window.alert("Resource not found!")
		});
	}

	return (
		<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<Button edge="start" color="inherit" onClick={handleClose} startIcon={<Close/>}>
						Close
					</Button>
				</Toolbar>
			</AppBar>
			<Box style={{margin: 30}}>
				<Box style={{marginBottom: 30}}>
					<Grid container spacing={2}>
						<Grid item sm={2}>
							<div>
								{
									getResourceFull(displayData.sampleWork1)
								}
								<Button color={"secondary"} variant="outlined" endIcon={<AspectRatio/>} onClick={() => {showOriginalImage(displayData.sampleWork1.url)}}>
									Expand
								</Button>
							</div>
						</Grid>
						<Grid item sm={10}>
							<Typography variant="body1" style={{fontSize: "large"}}>
								{displayData.sampleWork1.description}
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box style={{marginBottom: 30}}>
					<Grid container spacing={2}>
						<Grid item sm={2}>
							<div>
								{
									getResourceFull(displayData.sampleWork2)
								}
								<Button color={"secondary"} variant="outlined" endIcon={<AspectRatio/>} onClick={() => {showOriginalImage(displayData.sampleWork2.url)}}>
									Expand
								</Button>
							</div>
						</Grid>
						<Grid item sm={10}>
							<Typography variant="body1" style={{fontSize: "large"}}>
								{displayData.sampleWork2.description}
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box style={{marginBottom: 30}}>
					<Grid container spacing={2}>
						<Grid item sm={2}>
							<div>
								{
									getResourceFull(displayData.sampleWork3)
								}
								<Button color={"secondary"} variant="outlined" endIcon={<AspectRatio/>} onClick={() => {showOriginalImage(displayData.sampleWork3.url)}}>
									Expand
								</Button>
							</div>
						</Grid>
						<Grid item sm={10}>
							<Typography variant="body1" style={{fontSize: "large"}}>
								{displayData.sampleWork3.description}
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Dialog>
	);
}

export default ReviewDialog;