// React
import React, {useEffect, useState} from 'react';

// Redux deps
import {useDispatch, useSelector} from "react-redux";
import {setUserInfo} from "../Redux/user";

// Firebase deps
import {auth, db} from "../firebase";

// Components and Assets
import {
	Avatar,
	Button,
	ButtonGroup,
	Checkbox,
	CircularProgress,
	Container,
	Fab,
	FormControlLabel,
	Grid,
	IconButton,
	makeStyles,
	Snackbar,
	Tooltip,
	Typography
} from "@material-ui/core";
import {
	ExitToApp,
	KeyboardArrowUp,
	Save,
	ViewListOutlined,
	ViewModuleOutlined
} from "@material-ui/icons";
import Header from "./Header";
import ReviewCollection from "./ReviewCollection";
import artistApplications from "../jsonData2024_with_file_urls.json"
import ReviewDialog from "./ReviewDialog";
import MuiAlert from "@material-ui/lab/Alert";

// css styling for the Review page
const useStyles = makeStyles((theme) => ({
	psycheAvatar: {
		color: "white",
		backgroundColor: "#a53f5b",
	},
}));

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ReviewPageContent(props) {
	// Dispatch for redux
	const dispatch = useDispatch();

	// Selectors for redux to get user states
	const currentUser = useSelector((state) => state.user.currentUser);
	const firstName = useSelector((state) => state.user.firstName);
	const lastName = useSelector((state) => state.user.lastName);
	const accountType = useSelector((state) => state.user.accountType);
	const selectedArtists = useSelector((state) => state.user.selectedArtists);

	const classes = useStyles();

	const [loadingState, setLoadingState] = useState(false);
	const [studentWorks, setStudentWorks] = useState([]);

	useEffect(() => {
		if (accountType === "reviewer") {
			setLoadingState(true);
			db.collection("sampleData").get()
				.then((querySnapshot) => {
					const works = [];
					querySnapshot.forEach((doc) => {
						works.push(doc.data());
						console.log(doc.data());
					});
					setStudentWorks(works);
					setLoadingState(false);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			//  show warning ("you have been reported")
			//	report to us
			//  yeet them to home page
			console.log(selectedArtists);
		}
	}, []);

	// This state determines if user wants to see grid or list view
	const [showGridView, setShowGridView] = useState(true);

	// This state keep track of whether to show all cards or just selected ones
	const [checked, setChecked] = useState(false);
	const handleCheckEvent = (event) => {
		setChecked(event.target.checked);
	};

	// Dynamic snackbar error message
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [alertSeverity, setAlertSeverity] = useState("");
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackbar(false);
	};

	function saveReviews() {
		const userUID = JSON.parse(currentUser).uid;

		//	send to firebase
		db.collection("reviewers").doc(userUID).update({
			selectedArtists: selectedArtists
		}).then(() => {
			//	show success
			setOpenSnackbar(true);
			setSnackbarMessage("Your review has been successfully saved!");
			setAlertSeverity("success");
		}).catch((error) => {
			// show error
			setOpenSnackbar(true);
			setSnackbarMessage("There was an error trying to save your review. Please try again.");
			setAlertSeverity("error");
		});
	}

	return (
		<>
			<Container>
				{/*User login options*/}
				<Grid container alignItems="center" justifyContent="flex-end" style={{marginTop: 20}}>
					<Grid item>
						<Tooltip title={`Signed in as ${firstName} ${lastName}`}>
							<Avatar variant="rounded" src="/broken-image.jpg" className={classes.psycheAvatar}>
								{
									firstName.charAt(0) + lastName.charAt(0)
								}
							</Avatar>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip title="Sign out">
							<IconButton onClick={() => {
								//	sign out the user
								auth.signOut().then(() => {
									//	remove user from localStorage
									localStorage.setItem("user", null);

									//	remove user from redux
									const userInfo = {
										"currentUser": "",
										"firstName": "",
										"lastName": "",
										"emailAddress": "",
										"accountType": "",
										"selectedArtists": []
									}

									dispatch(setUserInfo(userInfo));

									//	Show sign out snackbar
								}).catch((error) => {
									//	Handle sign out error?
								});
							}}>
								<ExitToApp/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>

				{/*Site header*/}
				<Header title={"Psyche Inspired Application Review"} class={"Iridium Class"}/>

				{/*Site Content*/}
				<Typography variant="h3" style={{marginTop: 30, color: "#592651"}}>Instructions</Typography>
				<Typography variant="body1" style={{marginTop: 10, marginLeft: 10}}>
					<b>While reviewing the applications, keep this in mind:</b>
					<ul style={{margin: 0}}>
						<li>Is the work executed with high quality and care?</li>
						<li>How creative/original is the work?</li>
						<li>Are you excited at the thought of seeing more work like this?</li>
						<li>Do you think this type of work would be appropriate for communicating the Psyche mission?
						</li>
					</ul>
				</Typography>
				<Grid container style={{marginTop: 45}} alignItems={"flex-end"} direction={"column"}>
					<Grid item>
						<ButtonGroup color="primary" disableElevation>
							<Button
								startIcon={<ViewModuleOutlined/>}
								variant={showGridView ? "contained" : "outlined"}
								onClick={() => {
									setShowGridView(true)
								}}>
								View Grid
							</Button>
							<Button
								startIcon={<ViewListOutlined/>}
								variant={showGridView ? "outlined" : "contained"}
								onClick={() => {
									setShowGridView(false)
								}}>
								View List
							</Button>
						</ButtonGroup>
					</Grid>
					<Grid item>
						<FormControlLabel
							control={
								<Checkbox checked={checked} color="primary" onChange={handleCheckEvent}/>
							}
							label="Show only selected"
							style={{margin: 0}}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={4} style={{marginTop: 10, marginBottom: 50}}>
					{
						loadingState ?
							<CircularProgress style={{margin: "auto", marginTop: 50}}/>
							:
							artistApplications.map((submission) => (
								checked ?
									(
										selectedArtists.indexOf(submission.author) !== -1 ?
											<ReviewCollection
												submissionData={submission}
												selectStatus={true}
												showGridView={showGridView}
											/>
											:
											<></>
									)
									:
									(
										<ReviewCollection
											submissionData={submission}
											selectStatus={selectedArtists.indexOf(submission.author) !== -1}
											showGridView={showGridView}
										/>
									)
							))
					}
				</Grid>

				{/*Scroll up FAB*/}
				<Tooltip title={"Scroll to top"}>
					<Fab
						color="secondary"
						size={"medium"}
						style={{position: "fixed", bottom: 20, left: 20}}
						onClick={() => {
							document.body.scrollTop = 0;
							document.documentElement.scrollTop = 0;
						}
						}>
						<KeyboardArrowUp/>
					</Fab>
				</Tooltip>

				{/* Save/Submit FAB */}
				<Tooltip title={"Save review"}>
					<Fab variant="extended" color={"primary"} style={{position: "fixed", bottom: 20, right: 20}}
					     onClick={saveReviews}>
						<Save style={{marginRight: 5}}/>
						Save
					</Fab>
				</Tooltip>
			</Container>
			<ReviewDialog/>
			<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={alertSeverity}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
}

export default ReviewPageContent;