import {createSlice} from '@reduxjs/toolkit'

const initialState = {
	currentUser: "", // Stringified JSON. To access this obj do JSON.parse(currentUser)
	firstName: "",
	lastName: "",
	emailAddress: "",
	accountType: "",
	selectedArtists: []
}

export const userInfoSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserInfo: (state, action) => {
			// Payload can not contain objects. Convert them to string using JSON.stringify(user)
			state.currentUser = action.payload.currentUser
			state.firstName = action.payload.firstName
			state.lastName = action.payload.lastName
			state.emailAddress = action.payload.emailAddress
			state.accountType = action.payload.accountType
			state.selectedArtists = action.payload.selectedArtists
		},
		addArtist: (state, action) => {
			if (state.selectedArtists.indexOf(action.payload) === -1) {
				state.selectedArtists.push(action.payload);
			}
		},
		removeArtist: (state, action) => {
			const index = state.selectedArtists.indexOf(action.payload);
			if (index !== -1) {
				state.selectedArtists.splice(index, 1);
			}
		}
	},
})

// Action creators are generated for each case reducer function
export const {setUserInfo, addArtist, removeArtist} = userInfoSlice.actions

export default userInfoSlice.reducer