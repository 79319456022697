import {createTheme} from '@material-ui/core/styles';

const theme = createTheme({
	typography: {
		fontFamily: ["Rubik", 'sans-serif'].join(','),
		h1: {
			fontWeight: 700,
			fontSize: "xxx-large"
		},
		h2: {
			fontWeight: 700,
			fontSize: "xx-large"
		},
		h3: {
			fontWeight: 700,
			fontSize: "x-large"
		}
	},
	palette: {
		primary: {
			main: "#302144"
		},
		secondary: {
			main: "#f9a000"
		}
	}
})

export default theme;