import React from 'react';

function FormPage(props) {
	return (
		<div>

		</div>
	);
}

export default FormPage;