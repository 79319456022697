// React
import React, {useEffect, useState} from 'react';

// Material UI components
import {Container, LinearProgress, Snackbar} from "@material-ui/core";

// Auth form page
import AuthenticationForm from "../Components/AuthenticationForm";

// Redux deps
import {useDispatch, useSelector} from "react-redux";
import {setUserInfo} from "../Redux/user";

// Firebase deps
import {analytics, db} from "../firebase";

// Components
import ReviewPageContent from "../Components/ReviewPageContent";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ReviewPage(props) {
	// Dispatch for redux
	const dispatch = useDispatch();

	// Selectors for redux to get user states
	const currentUser = useSelector((state) => state.user.currentUser);

	// Dynamic snackbar error message
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const [loadingState, setLoadingState] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackbar(false);
	};

	// This state is used to show whether the user is logged in or not
	// TRUE = authenticated user exists, FALSE = logged out
	const [loginStatus, setLoginStatus] = useState(() => {
		const localUser = JSON.parse(localStorage.getItem("user"));

		if (localUser == null) {
			//	no user found
			return false;
		} else {
			//	user exists
			setLoadingState(true);

			// Get user from Firestore and set the details in redux
			db.collection("reviewers").doc(localUser.uid).get()
				.then((doc) => {
					if (doc.exists) {
						//	User exists in db
						const userData = doc.data();

						// Set user info in redux
						const userInfo = {
							"currentUser": JSON.stringify(localUser),
							"firstName": userData.firstName,
							"lastName": userData.lastName,
							"emailAddress": userData.emailAddress,
							"accountType": userData.accountType,
							"selectedArtists": userData.selectedArtists
						}

						dispatch(setUserInfo(userInfo));

						setLoadingState(false);
						return true;
					} else {
						setLoadingState(false);
						return false;
					}
				})
				.catch((error) => {
					const errorCode = error.code;
					const errorMessage = error.message;

					setOpenSnackbar(true);
					setSnackbarMessage("Sorry, we encountered an error while logging you in. Please refresh the page and try again.");

					analytics.logEvent('error', {
						location: 'review getting data for local user',
						errorCode: errorCode,
						errorMessage: errorMessage
					});

					return false;
				});
		}
	});

	// This re-renders to change the bg color based on changes in login status ONLY
	useEffect(() => {
		// Sets the background color to black if login page is being shown
		document.body.style.backgroundColor = loginStatus ? "#ffffff" : "#000000"
	}, [loginStatus]);

	// this changes the login status based on changes to the current user in redux
	useEffect(() => {
		setLoginStatus(currentUser !== "");
	}, [currentUser]);

	if (loadingState) return <LinearProgress/>;

	return (
		<Container>
			{
				loginStatus ? <ReviewPageContent/> : <AuthenticationForm accountType={"reviewers"}/>
			}
			<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error">
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</Container>
	);
}

export default ReviewPage;