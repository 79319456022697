import React, {useEffect, useState} from 'react';
import {
	Grid,
	GridList,
	GridListTile,
	IconButton,
	makeStyles,
	Tooltip,
	Typography,
	withStyles
} from "@material-ui/core";

import {Cancel, CheckCircle, Launch} from "@material-ui/icons";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {green, grey, red} from "@material-ui/core/colors";

import pdfIcon from "../Images/pdf-icon.jpg"
import playIcon from "../Images/play-icon.jpg"
import webIcon from "../Images/web-icon.jpg"

// Redux deps
import {useDispatch} from "react-redux";
import {addArtist, removeArtist} from "../Redux/user";
import {setDialogStatus} from "../Redux/dialog";

const useStyles = makeStyles(() => ({
	card: {
		padding: 20,
		borderColor: grey[200],
		borderWidth: 3,
		borderStyle: "solid",
		borderRadius: 10
	},
	cardSelected: {
		padding: 20,
		borderColor: green[500],
		borderWidth: 3,
		borderStyle: "solid",
		boxShadow: "0 0 8px rgba(76,175,80,0.75)",
		borderRadius: 10,

	},
	thumbnail: {
		borderRadius: 10
	}
}));

const GreenCheckIcon = withStyles(() => ({
	root: {
		color: green[500]
	}
}))(CheckCircle);

const RedCancelIcon = withStyles(() => ({
	root: {
		color: grey[500]
	}
}))(RadioButtonUncheckedIcon);

function ReviewCollection(props) {
	// Dispatch for redux
	const dispatch = useDispatch();

	const classes = useStyles();

	const tileData = props.submissionData;

	const [selectStatus, setSelectStatus] = useState(props.selectStatus);

	useEffect(() => {
		if (selectStatus) {
			// add
			dispatch(addArtist(tileData.author));
		} else {
			// remove
			dispatch(removeArtist(tileData.author));
		}
	}, [selectStatus]);

	function getResource(sampleWork) {
		if (sampleWork.url.includes(".pdf")) {
			return <img src={pdfIcon} alt={sampleWork.description} onClick={() => { window.open(sampleWork.url, "_blank") }} onMouseOver={() => { document.body.style.cursor = "pointer" }} onMouseOut={() => { document.body.style.cursor = "auto" }} />
		} else if (sampleWork.url.includes("youtu") || sampleWork.url.includes("vimeo")) {
			return <img src={playIcon} alt={sampleWork.description} onClick={() => { window.open(sampleWork.url, "_blank") }} onMouseOver={() => { document.body.style.cursor = "pointer" }} onMouseOut={() => { document.body.style.cursor = "auto" }} />
		} else if (sampleWork.url.includes("http")) {
			return <img src={webIcon} alt={sampleWork.description} onClick={() => { window.open(sampleWork.url, "_blank") }} onMouseOver={() => { document.body.style.cursor = "pointer" }} onMouseOut={() => { document.body.style.cursor = "auto" }} />
		}
		else {
			return <img src={sampleWork.url} alt={sampleWork.description} />
		}
	}

	function getResourceFull(sampleWork) {
		if (sampleWork.url.includes(".pdf")) {
			return <img width={"100%"} src={pdfIcon} alt={sampleWork.description} onClick={() => { window.open(sampleWork.url, "_blank") }} onMouseOver={() => { document.body.style.cursor = "pointer" }} onMouseOut={() => { document.body.style.cursor = "auto" }} />
		} else if (sampleWork.url.includes("youtu") || sampleWork.url.includes("vimeo")) {
			return <img width={"100%"} src={playIcon} alt={sampleWork.description} onClick={() => { window.open(sampleWork.url, "_blank") }} onMouseOver={() => { document.body.style.cursor = "pointer" }} onMouseOut={() => { document.body.style.cursor = "auto" }} />
		} else if (sampleWork.url.includes("http")) {
			return <img width={"100%"} src={webIcon} alt={sampleWork.description} onClick={() => { window.open(sampleWork.url, "_blank") }} onMouseOver={() => { document.body.style.cursor = "pointer" }} onMouseOut={() => { document.body.style.cursor = "auto" }} />
		}
		else {
			return <img width={"100%"} src={sampleWork.url} alt={sampleWork.description} />
		}
	}

	return (
		<Grid item xs={12} md={props.showGridView ? 6 : 12} lg={props.showGridView ? 4 : 12}>
			<div className={selectStatus ? classes.cardSelected : classes.card}>
				{
					props.showGridView ?
						<GridList cellHeight={160} cols={3}>
							<GridListTile className={classes.thumbnail} key={""} cols={1}>
								{
									getResource(tileData.sampleWork1)
								}
							</GridListTile>
							<GridListTile className={classes.thumbnail} key={""} cols={1}>
								{
									getResource(tileData.sampleWork2)
								}
							</GridListTile>
							<GridListTile className={classes.thumbnail} key={""} cols={1}>
								{
									getResource(tileData.sampleWork3)
								}
							</GridListTile>
						</GridList>
						:
						<Grid container spacing={3}>
							<Grid item sm={4}>
								{
									getResourceFull(tileData.sampleWork1)
								}
								<Typography variant={"body1"} style={{margin: 10}}>
									{
										tileData.sampleWork1.description
									}
								</Typography>
							</Grid>
							<Grid item sm={4}>
								{
									getResourceFull(tileData.sampleWork2)
								}
								<Typography variant={"body1"} style={{margin: 10}}>
									{
										tileData.sampleWork2.description
									}
								</Typography>
							</Grid>
							<Grid item sm={4}>
								{
									getResourceFull(tileData.sampleWork3)
								}
								<Typography variant={"body1"} style={{margin: 10}}>
									{
										tileData.sampleWork3.description
									}
								</Typography>
							</Grid>
						</Grid>
				}
				<Grid container justify="space-between" alignItems="center" style={{marginTop: "20px"}}>
					<Grid item>
						<Tooltip title={"Open"} placement="right" arrow>
							<IconButton onClick={() => {
								dispatch(setDialogStatus({
									dialogOpenStatus: true,
									displayData: tileData
								}))
							}}>
								<Launch/>
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip title={selectStatus ? "Unselect" : "Select"} placement="left" arrow>
							<IconButton onClick={() => {
								setSelectStatus(!selectStatus)
							}}>
								{
									selectStatus ? <GreenCheckIcon/> : <RedCancelIcon/>
								}
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</div>
		</Grid>
	);
}

export default ReviewCollection;