import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import logo from "../Images/Psyche_Icon_Color-PNG.png";

function Header(props) {
	return (
		<Grid container alignItems={"center"} spacing={3} style={{marginTop: 50}}>
			<Grid item>
				<img src={logo} alt="logo" width={100}/>
			</Grid>
			<Grid item>
				<Typography variant="h1" style={{color: "#302144"}}>
					{props.title}
				</Typography>
				<Typography variant="h2" style={{color: "#f9a000"}}>
					{props.class}
				</Typography>
			</Grid>
		</Grid>
	);
}

export default Header;