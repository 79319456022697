import React, { useEffect, useState } from 'react';
import { Container, Button, ButtonGroup, Grid } from "@material-ui/core";
import Header from "../Components/Header";
import { db } from '../firebase';
import artistApplications from "../jsonData2024_with_file_urls.json"

import { Bar } from 'react-chartjs-2';
import { CloudDownload, FormatListNumbered, SortByAlpha } from '@material-ui/icons';
import { CSVLink } from 'react-csv';

function ResultsPage(props) {

    const [numericalData, setNumericalData] = useState({});
    const [alphabeticalData, setAlphabeticalData] = useState({});
    const [artistsCount, setArtistsCount] = useState({});
    const [dataLoading, setDataLoading] = useState(true);
    const [redraw, setRedraw] = useState(false);

    const [showAlphaView, setShowAlphaView] = useState(false);

    const options = {
        indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
    };

    function getArtistNames() {
        const tempArtistsList = {};

        artistApplications.forEach((submission) => {
            tempArtistsList[submission.author] = 0;
        });

        return tempArtistsList;
    }

    useEffect(() => {
        setDataLoading(true);

        db.collection("reviewers").get().then((querySnapshot) => {
            // start async

            // local async data structure
            const artists = getArtistNames();

            querySnapshot.forEach((doc) => {
                doc.data().selectedArtists.forEach((name) => {
                    if(name in artists){
                        artists[name] += 1;
                    }
                });
            });

            const getAlphabeticalData = sortAlphabetically(artists);
            const getNumericalData = sortNumerically(artists);

            setAlphabeticalData(getAlphabeticalData);
            setNumericalData(getNumericalData);
            setArtistsCount(artists);

            setDataLoading(false);

            // end async
        });
    }, []);

    function sortAlphabetically(artists) {
        const tempData = {};

        const alphabeticallySorted = Object.keys(artists).sort().reduce(
            (obj, key) => {
                obj[key] = artists[key];
                return obj;
            },
            {}
        );

        let labels = [];
        let datasetData = [];

        for (const artistName in alphabeticallySorted) {
            if (Object.hasOwnProperty.call(alphabeticallySorted, artistName)) {
                labels.push(artistName);
                datasetData.push(alphabeticallySorted[artistName]);
            }
        }

        // set labels
        tempData["labels"] = labels;

        // set dataset
        const dataset = [];
        dataset.push({
            label: "# of votes",
            data: datasetData,
            backgroundColor: [
                'rgba(249, 160, 0, 0.2)',
            ],
            borderColor: [
                'rgba(249, 160, 0, 1)',
            ],
        });

        tempData["datasets"] = dataset;

        return tempData;
    }

    function sortNumerically(artists) {
        const tempData = {};

        const numericallySorted = Object.fromEntries(Object.entries(artists).sort((a, b) => b[1] - a[1]));

        // set chart data here
        let labels = [];
        let datasetData = [];

        for (const artistName in numericallySorted) {
            if (Object.hasOwnProperty.call(numericallySorted, artistName)) {
                labels.push(artistName);
                datasetData.push(numericallySorted[artistName]);
            }
        }

        // set labels
        tempData["labels"] = labels;

        // set dataset
        const dataset = [];
        dataset.push({
            label: "# of votes",
            data: datasetData,
            backgroundColor: [
                'rgba(249, 160, 0, 0.2)',
            ],
            borderColor: [
                'rgba(249, 160, 0, 1)',
            ],
        });

        tempData["datasets"] = dataset;

        return tempData;
    }

    return (
        <>
            <Container maxWidth={"lg"} style={{ marginTop: 50, marginBottom: 50 }}>
                <Header title={"Psyche Inspired Application Review Results"} class={"Iridium Class"} />

                <Grid container style={{ marginTop: 50 }} justifyContent="space-between">
                    <Grid item>
                        <CSVLink data={Object.entries(artistsCount)} headers={["Artist Name", "Number of Votes"]} filename={"Psyche Inspired Application Review Results.csv"}>
                            <Button variant="outlined" startIcon={<CloudDownload />}>
                                Download as CSV
                            </Button>
                        </CSVLink>
                    </Grid>
                    <Grid item>
                        <ButtonGroup color="primary" disableElevation>
                            <Button
                                startIcon={<SortByAlpha />}
                                variant={showAlphaView ? "contained" : "outlined"}
                                onClick={() => {
                                    setShowAlphaView(true);
                                }}>
                                View Alphabetically
                            </Button>
                            <Button
                                startIcon={<FormatListNumbered />}
                                variant={showAlphaView ? "outlined" : "contained"}
                                onClick={() => {
                                    setShowAlphaView(false)
                                }}>
                                View Ranking
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>

                {
                    showAlphaView
                        ?
                        <Bar data={alphabeticalData} height={500} options={options} style={{ marginTop: 25 }} redraw={redraw} />
                        :
                        <Bar data={numericalData} height={500} options={options} style={{ marginTop: 25 }} redraw={redraw} />
                }
            </Container>
        </>
    );
}

export default ResultsPage;