import React from 'react';
import {Container} from "@material-ui/core";
import Header from "../Components/Header";

function ManualEntryPage(props) {
	return (
		<Container>
			{/*Site header*/}
			<Header title={"Psyche Inspired Application Entry Form"}/>

			{/*Site Content*/}

		</Container>
	);
}

export default ManualEntryPage;