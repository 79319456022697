import {createSlice} from '@reduxjs/toolkit';
import dummyData from "../dataObjectStructure.json";

const initialState = {
	dialogOpenStatus: false,
	displayData: dummyData
}

export const dialogSlice = createSlice({
	name: 'dialog',
	initialState,
	reducers: {
		setDialogStatus: (state, action) => {
			state.dialogOpenStatus = action.payload.dialogOpenStatus;
			state.displayData = action.payload.displayData;
		}
	},
})

// Action creators are generated for each case reducer function
export const {setDialogStatus} = dialogSlice.actions

export default dialogSlice.reducer