import './App.css';

// Library Imports
import {BrowserRouter as Router, Route} from "react-router-dom"; // React Router for Navigation

// Pages
import HomePage from "./Pages/HomePage";
import FormPage from "./Pages/FormPage";
import ReviewPage from "./Pages/ReviewPage";
import ManualEntryPage from "./Pages/ManualEntryPage";
import ResultsPage from "./Pages/ResultsPage";

// Theme
import {ThemeProvider} from '@material-ui/core/styles';
import theme from "./Theme";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Route exact path="/" component={HomePage}/>
				<Route exact path="/form" component={FormPage}/>
				<Route exact path="/entry-form" component={ManualEntryPage}/>
				<Route exact path="/review" component={ReviewPage}/>
				<Route exact path="/results" component={ResultsPage}/>
			</Router>
		</ThemeProvider>
	);
}

export default App;
