import React from 'react';

function HomePage(props) {
	return (
		<div>
			<a href="https://psyche.asu.edu/get-involved/psyche-inspired/psyche-inspired-application-2021/">https://psyche.asu.edu/get-involved/psyche-inspired/psyche-inspired-application-2021/</a>
		</div>
	);
}

export default HomePage;